import React from "react"
import Actions from "./actions"
import Link from "./link"
import Fade from "react-reveal/Fade"
import GridContainer from '../blocks/GridContainer';

const Footer = ({ toggleShare, toggleSubscribe, toggleStopAnimation }) => (
	<footer id="footer" className="footer">
		{/* Get in touch section */}

		{
			window.location.pathname === '/work' ?
			<section className="awards grid lrg">
				<Fade bottom delay={0}>
					<div className="awards-grid">
						{/* <div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-prnews.png" alt="pr news logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2020 Top Places to Work</div>
						</div> */}
						<div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/the-one-club-for-creativity-logo-white.png" alt="The One Club for Creativity logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2023 & 2024 Agency Network of the Year</div>
						</div>
						<div className="grid-item">
							<img style={{maxHeight:'4.5em'}} src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/cannes-lions-1-logo.png" alt="Cannes Lions logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2019-2024 Regional Network of the Year – North America for six consecutive years, a first in Cannes Lions history</div>
						</div>
						<div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/one-show-logo-white.png" alt="The One Show logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2024 Network of the Year</div>
						</div>
						<div className="grid-item">
							<img style={{maxHeight:'2.5em'}} src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/the-good-report-2023-1024x380.png" alt="The Good Report logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">#1 in Best Networks Promoting Good</div>
						</div>
						<div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/adc-awards-logo-black.png" alt="ADC logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2024 Network of the Year</div>
						</div>
						<div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/footer-fast.png" alt="Fast Company logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2024 “World Changing Ideas”; 2022 Most Innovative</div>
						</div>
						<div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/cropped-adebc30e-node-drum-awards-rgb-original-large-3.png" alt="The Drum Awards logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2024 Network Agency of the Year</div>
						</div>
						<div className="grid-item">
							<img src="https://d1f5kcwhveewqf.cloudfront.net/uploads/footer/aotyg-landscape-white.png" alt="Campaign Agency of the Year Global logo" />
							<div className="awards-col-span-2 c-gray ff-cp fz1rem lh1.3">2023 Global Network of the Year (Creative/Advertising) - Bronze</div>
						</div>
					</div>
				</Fade>
			</section> : null
		}

		<section className="divider" />

		{
			window.location.pathname != '/careers/join-our-team' ?
		
			<section className="cta over-hidden">
				<Fade bottom delay={0}>
					<div className="t-h2">Get in touch</div>
				</Fade>
				<Fade bottom delay={200}>
					<div className="footer-links">
						<Link url="/contact-us" classProp="btn btn-link t-link">
							Become A Client
						</Link>
						<Link url="/careers/join-our-team" classProp="btn btn-link t-link">
							Join Our Team
						</Link>
					</div>
				</Fade>
			</section> : null
		}


		<Actions toggleShare={toggleShare} toggleStopAnimation={toggleStopAnimation} toggleSubscribe={toggleSubscribe} />

		{/* access section */}
		<section className="access">
		<div style={{
			display: 'grid',
			gridColumn: 'auto',
			gap: '1rem',
			gridAutoFlow: 'column',
			fontSize: '12px',
			fontFamily: 'code-pro',
			textDecoration: 'underline',
			cursor: 'pointer',
			alignItems: "center"
		}}>
				<a className="" href="https://fcbchi.com/media/5vxbt5xn/fcb-ipg-ca-privacy-notice-requirements-20250214.pdf" target="_blank">CCPA Privacy Notice</a>
				<Link
					url="/privacy-policy"
					classProp=""
				>
					Privacy Policy
				</Link>
				<Link
					url="/terms-and-conditions"
					classProp=""
				>
					Terms and Conditions
				</Link>
				<Link
					url="/press/press-kit"
					classProp=""
				>
					Press Kit
				</Link>
				<Link
					url="/contact-us"
					classProp="mr0">Contact Us</Link>
					<a id="ot-sdk-btn" className="ot-sdk-show-settings" style={{
					color: "white",
					fontFamily: "inherit",
					textDecoration: "underline",
					border: "none",
					fontSize: "inherit",
					padding: "revert",
					}}
					>Cookie Settings</a>
			</div>
			<div className="social-buttons df  pb2rem pt2rem">
				<div className="df jcsb aic">
					<a href="https://www.facebook.com/FCB.Global" title="Facebook" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-facebook" />
					</a>
					<a href="https://www.instagram.com/fcbglobal" title="Instagram" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-instagram" />
					</a>
					<a href="https://twitter.com/fcbglobal" title="Twitter" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-twitter" />
					</a>
					<a href="https://www.linkedin.com/company/fcbglobal/" title="Linkedin" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-linkedin" />
					</a>
					<a href="https://www.youtube.com/c/FCBglobal" title="youtube" className="btn btn-social mr3rem" target="_blank">
						<span className="ico ico-font ico-youtube" />
					</a>
				</div>
				<Link url="/" classProp="footer-logo ff-cp fw700">
					{/* <span className="ico ico-font ico-fcb-logo" /> */}
					&copy;&nbsp;{new Date().getFullYear()} FCB Global, Inc. All rights reserved.
				</Link>
			</div>
		</section>
	</footer>
)

export default Footer
