import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { fetchDetails } from "../../actions/request"
import moment from "moment"
import queryString from "query-string"
import { resetFilter } from "../../actions/filters"
import { Helmet } from "react-helmet"

import ImageHero from "../layout/imageHero"
import RichText from "../layout/richText"
import Intro from "../layout/intro"
import LineBreak from "../layout/lineBreak"
import PressMedia from "../layout/pressMedia"
import SitePagination from "../layout/sitePagination"
import PageLoadPlaceholder from "../layout/pageLoadPlaceholder"
import { widont } from "../../utils/utils"

const _ = require("lodash")

class PressCenterDetail extends Component {
	constructor(props) {
		super(props)

		this.state = {
			url: props.match.params.url,
			requestData: null
		}
	}

	_widont() {
		widont('.copy p')
	}

	componentWillMount() {
		const { filters, FetchDetails, resetFilter } = this.props

		if (filters.page !== "pressCenter") {
			resetFilter()
		}
		FetchDetails("press/articles", `${this.state.url}?${queryString.stringify(filters.active)}`)
		window.addEventListener('resize', this._widont)
		window.setTimeout(this._widont, 1000)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			const { filters, FetchDetails } = this.props

			FetchDetails("press/articles", `${this.props.match.params.url}?${queryString.stringify(filters.active)}`)
		}
		window.setTimeout(this._widont, 1000)

	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			requestData: nextProps.Request.data[0]
		})
		window.setTimeout(this._widont, 1000)

	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._widont)
	}

	render() {
		const { requestData } = this.state

		if (requestData === null || requestData instanceof Array || requestData === undefined) {
			return <PageLoadPlaceholder />
		} else {
			const heroImage = requestData.heroImage,
				title = requestData.title,
				content = requestData.content,
				category = requestData.category,
				media = requestData.media,
				mediaAltText = requestData.mediaAltText,
				author = requestData.author,
				{ office = {} } = requestData,
				{ city } = office,
				officeCity = (city && ` ${city}`) || "",
				aboutPress = requestData.page && requestData.page.aboutArticles ? requestData.page.aboutArticles.aboutArticles : null,
				hideAbout = requestData.hideAbout,
				// defaultHeader =
				// 	requestData.page && requestData.page.aboutArticles ? requestData.page.aboutArticles.pressCenterPage.heroImage : null,
				date = moment(requestData.date).format("DD MMMM YYYY"),
				nextUrl = requestData.nextUrl,
				prevUrl = requestData.prevUrl,
				metaTitle = requestData.metaData.title,
				metaDesc = requestData.metaData.description,
				metaImage = requestData.metaData.image,
				metaUrl = location.href,
				heroTitle = `${(category === "pressrelease") ? "Press Release" : ""}${officeCity}, ${date}`

			console.log(requestData)

			return (
			<div className="underline-links">
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="canonical" href={metaUrl} />
					<meta name="description" content={metaDesc} />
					<meta property="og:url" content={metaUrl} />
					<meta property="og:title" content={metaTitle} />
					<meta property="og:description" content={metaDesc} />
					<meta property="og:image" content={metaImage} />
					<meta name="twitter:title" content={metaTitle} />
					<meta name="twitter:description" content={metaDesc} />
					<meta name="twitter:image" content={metaImage} />
					<meta name="twitter:image:alt" content={metaTitle} />
				</Helmet>
				<style>
					{`
						@media all and (max-width: 1024px) {
							#holder {
								grid-template-columns: auto!important;
							}
						}
					`}
				</style>
				<ImageHero
					marginDisabled
					images={
						heroImage && ((heroImage.heroMobile && heroImage.heroWeb) || heroImage.mp4 || heroImage.webm) ? heroImage : ""
						// defaultHeader"
					}
					title={heroTitle}
					subtitle={title}
				/>
				<section className="site-content">
					{/* Article media - either an image or video */}
					{media && !_.isEmpty(media) && <PressMedia gridSize="med" media={media} />}

					{/* Article text */}
					{content && (
						<div className="grid lrg article-content" style={{display: 'grid', gap:'2rem', marginBottom: '2rem'}} dangerouslySetInnerHTML={{__html:content}}>
						</div>
					)}

					{/* Articles footer - the same for all articles */}
					{aboutPress && !hideAbout && (
							<div className="grid lrg" style={{display: 'grid', gap:'2rem', marginBottom: '2rem'}} dangerouslySetInnerHTML={{__html:aboutPress}}>
							</div>
					)}

					{/* Author Information */}
					{author && author.fullname && (
						<div className="author">
							<LineBreak gridSize="lrg" />
							<Intro gridSize="lrg">
								<RichText marginDisabled dropLastPadding textAlign="left">
									<h3>Author</h3>
									<p>
										{author.fullname && author.fullname}
										{author.phone && (
											<>
												<br />
												<a href={"tel:" + author.phone} title={author.phone}>
													T: {author.phone}
												</a>
											</>
										)}
										{author.email && (
											<>
												<br />
												<a href={"mailto:" + author.email} title={author.email}>
													E: {author.email}
												</a>
											</>
										)}
									</p>
								</RichText>
							</Intro>
						</div>
					)}
				</section>
				<SitePagination gridSize="lrg" title="press" permalink="/press/press-center" nextUrl={nextUrl} prevUrl={prevUrl} />
			</div>
			)
		}
	}
}

PressCenterDetail.defaultProps = {
	Request: new Object()
}

const mapStateToProps = state => ({
	filters: state.filters,
	Request: state.request
})

const mapDispatchToProps = dispatch => ({
	FetchDetails: (category, url) => dispatch(fetchDetails(category, url)),
	resetFilter: () => dispatch(resetFilter())
})

let connectedPage = connect(mapStateToProps, mapDispatchToProps)(PressCenterDetail)
connectedPage = withRouter(connectedPage)

export default connectedPage
